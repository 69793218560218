@use '@angular/material' as mat;

$o100w-contrast-color-dark: #001955;
$o100w-contrast-color-light: white;

$o100w-palette-darkblue: (
  50: #e5e7f0,
  100: #bdc3db,
  200: #929dc2,
  300: #6878aa,
  400: #495d9a,
  500: #26428b,
  600: #203b82,
  700: #173277,
  800: #0d296b,
  900: #001955,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-light,
    500: $o100w-contrast-color-light,
    600: $o100w-contrast-color-light,
    700: $o100w-contrast-color-light,
    800: $o100w-contrast-color-light,
    900: $o100w-contrast-color-light
  )
);

$o100w-palette-blue: (
  50: #e6f4ff,
  100: #badfff,
  //200: #8dcbff,
  200: #80c4ff,
  300: #5ab7ff,
  400: #2fa6ff,
  500: #0097ff,
  600: #0088ff,
  700: #0e75eb,
  800: #1463d8,
  900: #1943b9,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-dark,
    500: $o100w-contrast-color-light,
    600: $o100w-contrast-color-light,
    700: $o100w-contrast-color-light,
    800: $o100w-contrast-color-light,
    900: $o100w-contrast-color-light
  )
);

$o100w-palette-green: (
  50: #e1faf1,
  100: #b6f3dd,
  //200: #80ebc7,
  200: #8cf1d8,
  300: #19e3b1,
  400: #00db9e,
  500: #00d28c,
  600: #00c17f,
  700: #00ad70,
  800: #009c62,
  900: #007a49,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-dark,
    500: $o100w-contrast-color-light,
    600: $o100w-contrast-color-light,
    700: $o100w-contrast-color-light,
    800: $o100w-contrast-color-light,
    900: $o100w-contrast-color-light
  )
);

$o100w-palette-yellow: (
  50: #fffde5,
  100: #fff9c0,
  200: #fff595,
  //300: #fef167,
  300: #ffeb80,
  400: #fcec3f,
  500: #fae700,
  //600: #ffd900,
  600: #ffd700,
  700: #ffc000,
  800: #ffa700,
  900: #fe7a00,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-dark,
    500: $o100w-contrast-color-dark,
    600: $o100w-contrast-color-dark,
    700: $o100w-contrast-color-dark,
    800: $o100w-contrast-color-dark,
    900: $o100w-contrast-color-light
  )
);

$o100w-palette-red: (
  50: #ffeaee,
  //100: #ffc9d1,
  100: #feb1b5,
  200: #ff9295,
  300: #fc636b,
  400: #ff3043,
  500: #ff0725,
  600: #fd0026,
  700: #ea0021,
  800: #dd0019,
  900: #cd000b,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-dark,
    500: $o100w-contrast-color-light,
    600: $o100w-contrast-color-light,
    700: $o100w-contrast-color-light,
    800: $o100w-contrast-color-light,
    900: $o100w-contrast-color-light
  )
);

$o100w-palette-gray: (
  50: #f9f9f9,
  //100: #f2f2f2,
  100: #f3f3f3,
  200: #e9e9e9,
  //300: #d9d9d9,
  300: #d5d5d5,
  400: #b5b5b5,
  500: #959595,
  600: #6d6d6d,
  700: #595959,
  800: #3b3b3b,
  900: #1a1a1a,
  contrast: (
    50: $o100w-contrast-color-dark,
    100: $o100w-contrast-color-dark,
    200: $o100w-contrast-color-dark,
    300: $o100w-contrast-color-dark,
    400: $o100w-contrast-color-dark,
    500: $o100w-contrast-color-dark,
    600: $o100w-contrast-color-light,
    700: $o100w-contrast-color-light,
    800: $o100w-contrast-color-light,
    900: $o100w-contrast-color-light
  )
);

// define different brand palettes
$o100w-darkblue: mat.m2-define-palette($o100w-palette-darkblue, 900, 600, 900);
$o100w-blue: mat.m2-define-palette($o100w-palette-blue, 600, 200, 800);
$o100w-yellow: mat.m2-define-palette($o100w-palette-yellow, 600, 300, 800);
$o100w-green: mat.m2-define-palette($o100w-palette-green, 300, 200, 600);
$o100w-red: mat.m2-define-palette($o100w-palette-red, 300, 100, 400);
$o100w-gray: mat.m2-define-palette($o100w-palette-gray, 300, 100, 500);

// define 3 theme colors
$o100w-primary: $o100w-darkblue;
$o100w-accent: $o100w-blue;
$o100w-warn: $o100w-red;

$o100w-success-color: mat.m2-get-color-from-palette($o100w-green, "darker");
$o100w-error-color: mat.m2-get-color-from-palette($o100w-red, "darker");
$o100w-warning-color: mat.m2-get-color-from-palette($o100w-yellow, "darker");
$o100w-info-color: mat.m2-get-color-from-palette($o100w-blue, "darker");

$row-hover-color: #ddd;

$inactive-background-color: mat.m2-get-color-from-palette($o100w-gray, "darker-contrast");
$inactive-color: mat.m2-get-color-from-palette($o100w-gray, "darker");
$input-disabled: #ffe;

$legacy-input-border-color: rgba(0, 0, 0, 0.12);
$fake-input-border-radius: .25rem;

$survey-to-be-imported-color: mat.m2-get-color-from-palette($o100w-blue, "darker");
$survey-new-color: black;
$survey-accepted-color: $o100w-success-color;
$survey-rejected-color: $o100w-error-color;
$survey-in-progress-color: $o100w-warning-color;
$survey-reimport-color: $o100w-info-color;
